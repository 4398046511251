jQuery(function($) {
	var userAgent = window.navigator.userAgent.toLowerCase();

	if (userAgent.indexOf('msie') != -1 ||
		userAgent.indexOf('trident') != -1) {
		loadingwindow();
	} else {

		var webStorage = function() {
			if (sessionStorage.getItem('access')) {
				console.log('2回目以降のアクセス');
				jQuery('#js-loading').hide();
				jQuery('html').addClass('is-finished');
			} else {
				console.log('初回アクセス');
				loadingwindow();
				sessionStorage.setItem('access', 0);
			}
		}
		webStorage();

	}


	// loadingwindow();

	function loadingwindow() {
		jQuery(window).on('load', function() {
			var count = 0;
			var innterval = setInterval(function() {
				if (count == 4) {
					clearInterval(innterval);
					// console.log('finish');
					if (navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('iPad') !== -1) {
						scrolllock()
					}
					setTimeout(function() {
						$('html').addClass('is-finished');
						$('body').removeClass('navi_active');
						$('html').removeClass('is-navi-active');
						setTimeout(function() {
							$('html').removeClass('is-lock');
						}, 200);
					}, 100);

				} else {
					count++;
					count2 = count * 25;
					// console.log(count);
					$("#js-percent-text").text(count2);
					$('#js-progressbar').css({
						'width': count2 + '%'
					});
					$('html').addClass('is-lock');
					// $('body').addClass('navi_active');
					// $('html').addClass('is-navi-active');
					if (navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('iPad') !== -1) {
						scrolllock()
					}
				}
			}, 400);
		});
	}


});





function scrolllock() {
	if (!document.body.classList.contains('navi_active')) {
		// console.log('ok');
		document.body.classList.add('navi_active');

		var elem = document.getElementById('js-loading');
		elem.addEventListener('touchmove', function(e) {
			var scroll = elem.scrollTop;
			var range = elem.scrollHeight - elem.offsetHeight - 1;
			if (scroll < 1) {
				e.preventDefault();
				elem.scrollTop = 1;
			} else if (scroll > range) {
				e.preventDefault();
				elem.scrollTop = range;
			}
		});

	} else {
		// console.log('ok2');

		document.body.classList.remove('navi_active');
	}
}
//ブラウザ戻る進む対策（戻る進むでreload）
window.onpageshow = function(event) {
	if (event.persisted) {
		window.location.reload();
	}
}
